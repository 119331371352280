<template>
    <div class="materialProcurementSummary">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="按物料类型汇总" name="first">
                <div class="table-box">
                    <table cellspacing="0" border="1">
                        <thead>
                            <tr>
                                <th rowspan="2">
                                    物料名称
                                </th>
                                <th rowspan="2">
                                    规格型号
                                </th>
                                <th rowspan="2">
                                    需求量
                                </th>
                                <th rowspan="2">
                                    供应量
                                </th>
                                <th rowspan="2">
                                    差额
                                </th>
                                <th colspan="2">
                                    华能站
                                </th>
                                <th colspan="2">
                                    汇源站
                                </th>
                                <th colspan="2">
                                    沉香站
                                </th>
                                <th colspan="2">
                                    聚能站
                                </th>
                            </tr>
                            <tr>
                                <th>需求量</th>
                                <th>供应量</th>
                                <th>需求量</th>
                                <th>供应量</th>
                                <th>需求量</th>
                                <th>供应量</th>
                                <th>需求量</th>
                                <th>供应量</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>矿粉</td>
                                <td>S95</td>
                                <td>150000</td>
                                <td>150000</td>
                                <td>0</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>矿粉</td>
                                <td>S95</td>
                                <td>150000</td>
                                <td>150000</td>
                                <td>0</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>矿粉</td>
                                <td>S95</td>
                                <td>150000</td>
                                <td>150000</td>
                                <td>0</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>矿粉</td>
                                <td>S95</td>
                                <td>150000</td>
                                <td>150000</td>
                                <td>0</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>矿粉</td>
                                <td>S95</td>
                                <td>150000</td>
                                <td>150000</td>
                                <td>0</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>矿粉</td>
                                <td>S95</td>
                                <td>150000</td>
                                <td>150000</td>
                                <td>0</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                    <el-form ref="form" :model="form" label-width="80px">
                        <el-form-item label="申报人">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                        <el-form-item label="物质部主管">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                        <el-form-item label="总经理">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                    </el-form>
                    <footer>
                        <span class="btn report">上报</span>
                        <span class="btn cancel">取消</span>
                    </footer>
                </div>
            </el-tab-pane>
            <el-tab-pane label="按供应商汇总" name="second">
                <div class="table-box">
                    <table cellspacing="0" border="1">
                        <thead>
                            <tr>
                                <th>选择</th>
                                <th>供应商</th>
                                <th>物料名称</th>
                                <th>物料品牌</th>
                                <th>规格型号</th>
                                <th>资源地</th>
                                <th>单价</th>
                                <th>华能站</th>
                                <th>汇源站</th>
                                <th>城祥站</th>
                                <th>聚人站</th>
                                <th>合计</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td rowspan="4">
                                    <el-checkbox v-model="checked"></el-checkbox>
                                </td>
                                <td rowspan="4">
                                    A供应商
                                </td>
                                <td>矿粉</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>粉煤灰</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>粉煤灰</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>粉煤灰</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>9</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td rowspan="4">
                                    <el-checkbox v-model="checked"></el-checkbox>
                                </td>
                                <td rowspan="4">
                                    A供应商
                                </td>
                                <td>矿粉</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>粉煤灰</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>粉煤灰</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>粉煤灰</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>9</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td rowspan="4">
                                    <el-checkbox v-model="checked"></el-checkbox>
                                </td>
                                <td rowspan="4">
                                    A供应商
                                </td>
                                <td>矿粉</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>粉煤灰</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>粉煤灰</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>粉煤灰</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>9</td>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td rowspan="4">
                                    <el-checkbox v-model="checked"></el-checkbox>
                                </td>
                                <td rowspan="4">
                                    A供应商
                                </td>
                                <td>矿粉</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>粉煤灰</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>粉煤灰</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>9</td>
                            </tr>
                            <tr>
                                <td>粉煤灰</td>
                                <td>1</td>
                                <td>2</td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                                <td>6</td>
                                <td>7</td>
                                <td>8</td>
                                <td>9</td>
                            </tr>
                        </tbody>
                    </table>
                    <footer>
                        <span class="btn report">上报</span>
                        <span class="btn cancel">取消</span>
                    </footer>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // 控制tabs选择项
            activeName: 'first',
            // 上报提交的信息对象
            form: {

            },
        };
    },
    methods: {
        handleClick() {

        },
    },
};
</script>

<style  lang="stylus">
    .el-tabs
        width 100%
        height 0.46rem
        margin-bottom 0.14rem
        .el-tabs__header
            margin-bottom 0
            .el-tabs__nav-wrap
                &:after
                    display none
                margin-bottom 0.14rem
                .el-tabs__item
                    height 0.46rem
                    font-size 0.16rem
                    font-weight 400
                    line-height 0.34rem
                    font-weight 600
                    &.is-active,
                    &:hover
                        color #D6000F
                    i
                        margin-right 0.05rem
                    span
                        padding 0 0.3rem
                .el-tabs__active-bar
                    background-color #D6000F
    .table-box
        padding 0.1rem
        table
            width 100%
            border-spacing  0
            border-collapse collapse
            text-align center
            thead
                background rgba(236,241,246,1)
                font-size 0.16rem
                font-family Source Han Sans CN
                font-weight 500
                color rgba(2,39,130,1)
            tr
                height 0.35rem
                border 1px solid rgba(215,215,215,1)
                td
                    border 1px solid rgba(215,215,215,1)
                    font-size 0.16rem
                    font-family Source Han Sans CN
                    font-weight 400
                    color rgba(51,51,51,1)
                    line-height 0.2rem
        footer
            height 0.4rem
            text-align center
            margin-top 0.2rem
            .btn
                width 0.7rem
                height 0.4rem
                margin 0 0.2rem
                display inline-block
                line-height 0.38rem
                text-align center
                box-sizing border-box
                &.report
                    background rgba(21,119,210,1)
                    border-radius 2px
                    color #ffffff
                &.cancel
                    border 0.02rem solid rgba(21,119,210,1)
                    border-radius: 2px;
                    color rgba(21,119,210,1)


</style>